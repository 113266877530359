@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Pixeled';
  src: url('../assets/fonts/Pixeled.ttf');
}

@layer base {
  html,
  body {
    @apply bg-application-dark;
    height: 100vh;
    height: -webkit-fill-available;
  }

  body {
    min-height: -webkit-fill-available;
  }

  .clickthrough {
    pointer-events: none;
  }

  .clickon {
    pointer-events: auto;
  }

  .fitter-wrapper {
    height: 100vh;
    height: -webkit-fill-available;
  }

  .fitter-ratio {
    padding-top: calc(144 / 256 * 100%);
  }

  .fitter-ratio.prompted {
    min-height: 100vh;
    height: -webkit-fill-available;
  }

  .fitter-ratio.not-prompted {
    height: 0;
  }

  #root {
    position: relative;
  }

  #gameRoot canvas {
    z-index: 9;
    position: relative;
    /* margin-bottom: 1em; */
  }

  /** Content */
  ul {
    padding-left: 1.5em;
    list-style-type: disc;
  }

  a {
    text-decoration: underline;
    transition: all 0.2s;
  }

  a:hover {
    color: #3babe2;
  }

  .disclaimer p {
    padding-bottom: 1rem;
  }

  /** Intro Banner */
  .intro-banner {
    background-repeat: no-repeat;
    background-position: 100% 100%;
    background-size: 25%;
  }

  /** Aspect Ratios */
  .aspect-ratio-box {
    height: 0;
    overflow: hidden;
    position: relative;
  }
  .aspect-ratio-box-inside {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .responsive-img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  /** Pixel Image */
  .pixel-img {
    image-rendering: optimizeSpeed;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: pixelated;
    image-rendering: optimize-contrast;
    -ms-interpolation-mode: nearest-neighbor;
  }

  .inline-pixel-chevron {
    display: inline;
    width: 25px;
    height: auto;
    margin-bottom: 5px;
  }

  /** Pixel Button */
  .pixel-button {
    position: relative;
    z-index: 2;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .pixel-button:active {
    top: 2px;
  }

  .pixel-button::before {
    content: '';
    display: block;
    position: absolute;
    top: 3px;
    bottom: 3px;
    left: -9px;
    right: -9px;
    z-index: -1;
  }

  .pixel-button::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: -6px;
    right: -6px;
    z-index: -1;
  }

  /** Pixel Border */
  .pixel-this:before,
  .pixel-this:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    box-sizing: content-box;
    z-index: 0;
  }

  .pixel-this:before {
    top: -6px;
    left: 0;
    border-top: 6px white solid;
    border-bottom: 6px white solid;
  }

  .pixel-this:after {
    left: -6px;
    top: 0;
    border-left: 6px white solid;
    border-right: 6px white solid;
  }

  /** Masked Icons */
  .mask-test {
    width: 30px;
    height: 30px;
    position: relative;
    overflow: hidden;
  }

  .mask-test img {
    position: absolute;
    left: 0;
    top: 0;
  }
  .mask-test.empty img {
    right: 0;
    left: auto;
  }

  /** Debug */
  .terminal {
    width: 20em;
    margin-top: 12px;
    border-radius: 5px 5px 0 0;
    position: relative;
  }
  .terminal .top {
    background: #e8e6e8;
    color: black;
    padding: 5px;
    border-radius: 5px 5px 0 0;
  }
  .terminal .btns {
    position: absolute;
    top: 7px;
    left: 5px;
  }
  .terminal .circle {
    width: 12px;
    height: 12px;
    display: inline-block;
    border-radius: 15px;
    margin-left: 2px;
    border-width: 1px;
    border-style: solid;
  }
  .title {
    text-align: center;
  }
  .red {
    background: #ec6a5f;
    border-color: #d04e42;
  }
  .green {
    background: #64cc57;
    border-color: #4ea73b;
  }
  .yellow {
    background: #f5c04f;
    border-color: #d6a13d;
  }
  .clear {
    clear: both;
  }
  .terminal .body {
    background: black;
    color: #7afb4c;
    padding: 8px;
    /* overflow: auto; */
    border-radius: 0.3em;
  }
  .space {
    margin: 25px;
  }
  .shadow {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
  }
}

.score-value {
  font-size: 1.3rem;
}

.life {
  width: 32px;
}

.table-container {
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin: auto;
  caption-side: top;
  empty-cells: hide;
  table-layout: auto;
}

th,
td {
  padding: 1rem;
  text-align: left;
}

tr:nth-child(even) {
  background-color: #ddd;
}

.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
